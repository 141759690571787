import React from "react";
import { Formik, Form } from "formik";
import {
  Button,
  Label,
  Input,
  Error,
  SmallContainer,
  Title,
  ModalOverlay,
  Modal,
  ModalClose
} from "../utils/styled-components-library";
import API from "../utils/API";
import * as Auth from "../utils/Auth";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const ForgotPassword = styled.div`
  color: #1d529c;
  margin: 10px 0;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showModalForgotPassword: false,
      passwordResetQueried: false
    };
  }

  render() {
    return (
      <>
        <SmallContainer>
          <Title>Connexion à ProVote</Title>
          <Formik
            initialValues={{
              email: "",
              password: ""
            }}
            validate={values => {
              return {};
            }}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              API.post("/login", values, data => {
                setSubmitting(false);
                if (data.success) {
                  Auth.setToken(data.token);
                    this.props.history.push("/");
                  this.props.onSuccess(data.user);
                } else if (data.error) {
                  setErrors({ [data.field]: data.error });
                }
              });
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Label>Adresse email</Label>
                <Input name="email" type="email" />
                <Error name="email" component="div" />
                <Label>Mot de passe</Label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    style={{ marginRight: "15px" }}
                  />
                  <i
                    className={
                      !this.state.showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                    }
                    style={{
                      color: "#1D529C",
                      fontSize: "18px",
                      marginTop: -12,
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      this.setState({ showPassword: !this.state.showPassword })
                    }
                  />
                </div>
                <Error name="password" component="div" />
                <Button type="submit" disabled={isSubmitting}>
                  Se connecter
                </Button>
                <ForgotPassword
                  onClick={() =>
                    this.setState({
                      showModalForgotPassword: true,
                      passwordResetQueried: false
                    })
                  }
                >
                  Mot de passe votant oublié ?
                </ForgotPassword>
                <div style={{ marginTop: 20, fontSize: 13 }}>
                  ProVote® by Mediavote
                  <br />
                  Développement 100% français
                  <br />
                  <a href="https://www.media-vote.com/">www.media-vote.com</a>
                  <img
                    src="/img/home.png"
                    style={{ width: "100%", marginTop: 20 }}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </SmallContainer>

        {this.state.showModalForgotPassword && (
          <ModalOverlay
            onClick={() => {
              window.location.hash = "";
              this.setState({ showModalForgotPassword: false });
            }}
          >
            <Modal onClick={e => e.stopPropagation()}>
              <ModalClose
                onClick={() => {
                  window.location.hash = "";
                  this.setState({ showModalForgotPassword: false });
                }}
              />
              {this.state.passwordResetQueried ? (
                <>
                  <h4>
                    Votre demande de réinitialisation de mot de passe a été
                    envoyée.
                  </h4>
                  <Button
                    onClick={() => {
                      this.setState({ showModalForgotPassword: false });
                    }}
                  >
                    Fermer
                  </Button>
                </>
              ) : (
                <>
                  <h4>Vous avez oublié votre mot de passe de votant ?</h4>
                  <p>
                    Nous allons vous assister dans sa récupération. Veuillez
                    entrer l'adresse mail liée à votre compte puis cliquer sur
                    "Envoyer". Selon le mode d'envoi définie par votre session de vote, vous
                    recevrez votre mot de passe par email ou SMS dans les
                    prochaines minutes.
                  </p>
                  <Formik
                    initialValues={{ email: "" }}
                    validate={values => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = "Veuillez entrer votre adresse email.";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting, setErrors }) => {
                      setSubmitting(true);
                      API.post(
                        "/updateVoterPassword",
                        { forgotPassword: true, ...values },
                        data => {
                          setSubmitting(false);
                          if (data.success) {
                            this.setState({ passwordResetQueried: true });
                          } else {
                            setErrors({ email: data.message });
                          }
                        }
                      );
                    }}
                    validateOnMount
                  >
                    {({ isSubmitting, isValid, values }) => (
                      <Form>
                        <Label>Email</Label>
                        <Input name="email" type="email" />
                        <Error
                          component="div"
                          name="email"
                          style={{ textAlign: "center" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "10px"
                          }}
                        >
                          <Button
                            onClick={this.submit}
                            disabled={!isValid || isSubmitting}
                            style={{ marginRight: "10px" }}
                          >
                            Envoyer
                          </Button>
                          <Button
                            white
                            onClick={() => {
                              this.setState({ showModalForgotPassword: false });
                            }}
                          >
                            Annuler
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </Modal>
          </ModalOverlay>
        )}
      </>
    );
  }
}

export default withRouter(Login);
