import React from "react";
import { ChartContainer, WinnerText, DrawText } from "../../utils/styled-components-library";
import styled from "styled-components";
import Chart from "./Chart";
import TableVoteResults from "./TableVoteResults";

const SmallTitle = styled.div`
  font-size: 24px;
  margin: 0 auto;
  margin-bottom: 15px;
  text-align: center;
`;

export default function RoundBasedVoteResults({ vote, printing, index }) {
  const colors = {
    voteAbstain: "#FF9800",
    voteExtraOption: "#42a5f5",
    null: "#1D529C",
  };

  

  const sortedVoteResults = vote.voteResults.sort((a, b) => {
    return (b.count || 0) - (a.count || 0);
  });

  const castedVoteCount = vote.useVotePowers 
    ? vote.castedVotesWithPowers : vote.castedVotes;
  const nonCastedVoteCount = vote.useVotePowers
    ? vote.expectedVotesWithPowers - vote.castedVotesWithPowers
    : vote.expectedVotes - vote.castedVotes

  const getWinners = () => {
    let max = 0;
    let winner = null;
    vote.voteResults
      .filter((x) => x.optionType !== "voteAbstain")
      .forEach((voteResult) => {
        if (voteResult.count >= max) {
          max = voteResult.count;
          winner = voteResult;
        }
      });
    return [winner];
  };
  const isTie = () => {
    let max = 0;
    let tie = false;
    vote.voteResults
      .filter((x) => x.optionType !== "voteAbstain")
      .forEach((voteResult) => {
        if (voteResult.count > max) {
          max = voteResult.count;
          tie = false;
        } else if (voteResult.count === max) {
          tie = true;
        }
      });
    return tie;
  };
  const showTwoRoundsElectionsResults = () => {
    if (vote.voteType === "oneRoundElection") {
      // à refactorer, on appelle cette méthode pour les élection à 1 tour, il faut toujours afficher les résultats pour ces élections
      return true;
    }

    let castedVotes = vote.useVotePowers
      ? vote.castedVotesWithPowers
      : vote.castedVotes;
    let expectedVotes = vote.useVotePowers
      ? vote.expectedVotes
      : vote.expectedVotesWithPowers;

    // Deuxième tour de l'élection : on affiche les résultats
    if (vote.electionRound === 2) {
      return true;
    }

    // Moins de 25% des électeurs ont voté : on n'affiche pas les résultats
    if (castedVotes < expectedVotes / 4) {
      return false;
    }

    // Un candidat a au moins 50% des voix
    let totalNumberOfVotes = vote.voteResults.reduce(
      (sum, val) => sum + val.count,
      0
    );

    return vote.voteResults
      .filter((x) => !x.optionType)
      .some((result) => result.count > totalNumberOfVotes / 2);
  };

  const winners = getWinners();

  return (
    <ChartContainer
      key={vote.voteId}
      printing={printing}
      index={index}
    >
      <SmallTitle>{vote.voteName}</SmallTitle>
      <p>
        Nombre de suffrage(s) exprimé(s) : {castedVoteCount} <br />
        Nombre de non voté(s) : {nonCastedVoteCount}
      </p>

      <div style={{ fontWeight: "500", textAlign: "center" }}>
        {}
        {showTwoRoundsElectionsResults(vote) ? (
          isTie() ? (
            <>
              <DrawText>
                Pas de majorité dégagée en raison d'une ou plusieurs
                égalité(s)
              </DrawText>
            </>
          ) : (
            <>
              {winners.length === 1 ? (
                <>
                  {winners.map((winner) => {
                    return (
                      <WinnerText key={winner.optionId}>
                        {winner.optionName}
                      </WinnerText>
                    );
                  })}{" "}
                  remporte le nombre le plus élevé de suffrages
                  exprimés.
                </>
              ) : winners.length > 1 ? (
                <>
                  Les candidats élus sont :
                  {winners.map((winner) => {
                    return (
                      <WinnerText key={winner.optionId}>
                        {winner.optionName}
                      </WinnerText>
                    );
                  })}
                </>
              ) : null}
            </>
          )
        ) : vote.voteType === "oneRoundElection" ? (
          <DrawText>
            Pas de majorité dégagée en raison d'une ou plusieurs
            égalité(s)
          </DrawText>
        ) : (
          <DrawText>
            Aucun candidat n'a été élu. Un deuxième tour doit être
            organisé.
          </DrawText>
        )}
      </div>

      { vote.resultGraphType === "table" ? 
        (
          <TableVoteResults
            resultDisplayType={vote.resultDisplayType}
            voteResults={sortedVoteResults}
            voteType={vote.voteType}
          />
        ) : (
          <Chart
            chartType={vote.resultGraphType}
            displayType={vote.resultDisplayType}
            data={sortedVoteResults.map((x) => x.count)}
            labels={sortedVoteResults.map((x) =>
              x.optionType === "voteAbstain"
                ? "Vote(s) blanc(s)"
                : x.optionName
            )}
            colors={sortedVoteResults.map((x) => colors[x.optionType])}
          />
        )
      }
    </ChartContainer>
  )
}